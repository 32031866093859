

function toggleLabels(element){

	if($(element).val() === ""){
		$("label[for='"+$(element).attr('name')+"']").css("visibility", "visible");
	}else{
		$("label[for='"+$(element).attr('name')+"']").css("visibility", "hidden");
	}
}


function autoCompleatToggle(hit){

	$(window).load(function(){
		/*alert();*/	   	
	   	if($('input:-webkit-autofill').val()){
	      $("label[for='pswd'], label[for='email']").css("visibility", "hidden");   
	   }        
	});

}

function showTooltip(trigger) {
	
	$(".tooltip").css('display', 'none');
	$(".tooltip .diskBtn").click(function(){ $(".tooltip").fadeOut();});
	
	$("#"+trigger.id+"TT").
	focus().
	fadeIn().
	css({
		'left' : $(trigger).offset().left - ($("#"+trigger.id+"TT").width() - 31), 
		'top': $(trigger).offset().top + $(trigger).height() + 8
		}).
	hover(
		function(){ $(".tooltip .diskBtn").fadeIn(150); },
		function(){ $(".tooltip .diskBtn").fadeOut(300); }
		)

};



function groupValidation(){
	
	var cleared = true;
	
	/*---Passwrd Match---*/		
	if ($("#confirmPassword").length > 0) {
	
		
		if($("#confirmPassword").val() != $("#password").val()){
			$("#confirmPassword").css("background-color", "#fdd8d8");
			$("#password").css("background-color", "#fdd8d8");
			$("#confirmPassword"+"ValMsg").css("display", "block").text("your entered passwords do not match");
			cleared = false;
		}
	}
	/*-------------------------------*/
	
	
	/*---Contact (one of the above)---*/		
	if ($("#email").length > 0 && $("#daytimeTel").length > 0 && $("#eveningTel").length > 0 && $("#mobile").length > 0) {
		
		var allContacts = $("#email").val() + $("#daytimeTel").val() + $("#eveningTel").val() + $("#mobile").val();
		
		if(allContacts == ""){
			$("#email").css("background-color", "#fdd8d8"); 
			$("#daytimeTel").css("background-color", "#fdd8d8"); 
			$("#eveningTel").css("background-color", "#fdd8d8"); 
			$("#mobile").css("background-color", "#fdd8d8");
			$("#mobile"+"ValMsg").css("display", "block").text("At least one of the above contact options must be entered");
			cleared = false;
		}
	}
	/*-------------------------------*/
	
	
	return cleared;
	
}



function validateValue(name, value) {
	
	var cleared = true; 
	if(fieldValues.hasOwnProperty(name)){
	
	 
		if(fieldValues[name].hasOwnProperty("regex")){ 
			var thisRegex = new RegExp(fieldValues[name]["regex"]);
			if(!thisRegex.test(value)){ 
				$("#"+name).css("background-color", "#fdd8d8"); 
				$("#"+name+"ValMsg").css("display", "block").text(fieldValues[name]["lnOut"]);
				cleared = false;
			}else{
				$("#"+name).css("background-color", "");
				$("#"+name+"ValMsg").css("display", "none");
			} 
		} 
	} 
	
	return cleared;
}


function validateformPush(formIn){ 

	var cleared = true;
	for (var i=0; i<formIn.length; i++) { 
	
		if(formIn[i].type !== 'fieldset' && formIn[i].type !== 'hidden' && formIn[i].type !== 'submit'  && formIn[i].type !== 'button'){ 
			if(!validateValue(formIn[i].name.replace("VFQ-", ""), formIn[i].value)){
				cleared = false;
			} 
		} 
	}
	
	if(!groupValidation()){
		cleared = false;
	}
	
	return cleared;
}


function iframeFix(){
	$("iframe").each(function(){
		if ($(this).css('visibility') == 'hidden'){
		$(this).css('display', 'none');
		}
	});
}

